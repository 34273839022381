body {
  overflow: hidden;
}
@font-face {
  font-family: 'DINMedium';
  src: url(fonts/din-medium.woff) format('woff');
}
body .blue {
  color: #2c6aa8;
}
body a,
body a:visited {
  color: #2c6aa8;
}
body div,
body p,
body a,
body li,
body td {
  -webkit-text-size-adjust: none;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10%;
  margin-left: -5%;
  margin-top: -7%;
  vertical-align: center;
  transition: opacity 0.5s;
  opacity: 0;
}
.spinner.visible {
  opacity: 1;
}
.spinner img {
  animation: rotation 2s infinite linear;
  width: 100%;
}
.spinner img.spinning {
  transform: rotate(180deg);
}
@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}
.main {
  transition: opacity 1s;
  opacity: 0;
  display: none;
}
.main.visible {
  opacity: 1;
  display: inherit;
}
.main .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}
.main .scene {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30%;
  margin-top: -295px;
  width: 60%;
  height: 590px;
}
.main .scene .card {
  perspective: 3000px;
  transform-style: preserve-3d;
  position: absolute;
  width: 100%;
  height: 100%;
}
.main .scene .card .image {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.main .scene .card .front,
.main .scene .card .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  border: 1px solid black;
}
.main .scene .card .front,
.main .scene .card .back {
  transform-style: preserve-3d;
  transition: transform 2s;
}
.main .scene .card .front {
  background-color: white;
  transform-origin: 0 50%;
  transform: rotateY(0deg);
}
.main .scene .card.flipped .front {
  transform: rotateY(-180deg);
}
.main .scene .card .front img {
  z-index: 2;
}
.main .scene .card .back {
  width: 100%;
  height: 100%;
  left: -100%;
  background-color: white;
  border: 1px solid black;
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -moz-box;
  -moz-box-pack: center;
  -moz-box-align: center;
  transform: rotateY(180deg);
  transform-origin: right 0;
}
.main .scene .card.flipped .back {
  transform: rotateY(0deg);
}
.main .scene .back-card {
  background-color: white;
  font-family: 'DINMedium', sans-serif;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.main .scene .back-card .content {
  margin: 5%;
  font-size: 1.75em;
}
.main .scene .back-card .content p:not(.header) {
  font-size: 0.75em;
}
.main .scene .back-card .content .signatures .photo {
  transition: opacity 2s;
  position: absolute;
  left: 80%;
  bottom: 5%;
  width: 15%;
  opacity: 0;
}
.main .scene .back-card .content .signatures .photo.visible {
  opacity: 1;
}
.main .scene .back-card .content .signatures .photo img {
  width: 80%;
  margin-left: 10%;
  cursor: pointer;
}
.main .scene .back-card .content .signatures .photo div {
  text-align: center;
  font-size: 0.7em;
}
.main .buttons {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
.main .buttons .button {
  width: 5%;
  height: 5%;
  min-width: 35px;
  z-index: 1;
}
